import React from 'react'
import tw from 'twin.macro'

export default function Divide(props) {
  const { border, marginTop, marginBottom, width } = props.content

  return (
    <section
      className={`${border && 'border-b-2'} divider`}
      css={[
        border && tw`border-b-2 border-sand`,
        marginTop === 'sm' && tw`mb-2 md:mt-6`,
        marginTop === 'md' && tw`mb-6 md:mt-12`,
        marginTop === 'lg' && tw`mb-6 md:mt-24`,
        marginBottom === 'sm' && tw`mb-2 md:mb-6`,
        marginBottom === 'md' && tw`mb-6 md:mb-12`,
        marginBottom === 'lg' && tw`mb-6 md:mb-24`,
        width === 'contained' && tw`container`,
      ]}
    ></section>
  )
}
